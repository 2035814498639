document.addEventListener('alpine:init', () => {
	Alpine.data('dataArray', () => ({
		videos: [
			{
				title: 'Video 1',
				url: './videos/video-01.mp4',
			},
			{
				title: 'Video 2',
				url: './videos/video-02.mp4',
			},
			{
				title: 'Video 3',
				url: './videos/video-03.mp4',
			},
		],

		downloads: [
			{
				title: 'ofi intro with sound',
				url: './videos/video-01.mp4',
			},
			{
				title: 'ofi intro without sound',
				url: './videos/video-02.mp4',
			},
		],

		slugify(str) {
			str = str.replace(/^\s+|\s+$/g, ''); // trim
			str = str.toLowerCase();

			// remove accents, swap ñ for n, etc
			var from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
			var to = 'aaaaaaeeeeiiiioooouuuunc------';

			for (var i = 0, l = from.length; i < l; i++) {
				str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
			}

			str = str
				.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
				.replace(/\s+/g, '-') // collapse whitespace and replace by -
				.replace(/-+/g, '-') // collapse dashes
				.replace(/^-+/, '') // trim - from start of text
				.replace(/-+$/, ''); // trim - from end of text

			return str;
		},
	}));
});
